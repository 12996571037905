

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Poppins', sans-serif;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.helpSecond{
  display: none;
}
@media screen and (max-width: 998px) 
{
  .helpFirst{
    display: none;
  }
  .helpSecond{
    display: block;
    width: 80%;
    
    margin: auto;
    margin-top: 10px;
    margin-bottom: 15px;
  }
}
.footer-logo{
  color: transparent;
  width:50%;
  /* height: 600px; */
}
  

