.main-div {
  background-color: #e9ecef;
  border-radius: 5px;
}
.first-heading {
  color: #f97201;
  font-family: Inter;
  font-size: 30px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
}
.input:focus {
  border: 1px solid #f97201;
}
.note {
  color: #f97201;
  cursor: pointer;
}
.btn1 {
  width: 100px;
  color: white;
  background-color: #f97201;
}
.btn2 {
  width: 100px;
  color: white;
}

.Certificat-div {
  background-color: #e9ecef;
  border-radius: 5px;
}
.children-div {
  background-color: #e9ecef;
  border-radius: 5px;
}
